<template>
  <div ref="rootEl" class="hero-picture">
    <div class="wrapper">
      <span v-if="content.headline" ref="headlineEl" class="bold">
        {{ content.headline }}
      </span>

      <h1 ref="titleEl" class="bold" v-html="content.title"></h1>

      <p ref="textEl" class="hero-text" v-html="content.text"></p>

      <div v-if="content.links" ref="linksEl" class="hero-links">
        <Action
          v-for="(link, index) in content.links"
          :key="link?.url || index"
          :content="{
            ...link,
            modifiers:
              index === 0 ? ['btn', 'cta'] : ['btn', 'bordered', 'white'],
          }"
          class="focus__links__item"
        />
      </div>
    </div>

    <div ref="pictureEl" class="hero-picture__container">
      <img
        v-if="content.desktop_picture && !isMobile"
        v-src="content.desktop_picture"
        :alt="content.desktop_picture.alt"
        sizes="90vw"
      />
      <img
        v-if="content.mobile_picture && isMobile"
        v-src="content.mobile_picture"
        :alt="content.mobile_picture.alt"
        sizes="90vw"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { onMounted, ref } from 'vue'

import { useResize } from '@/utils/aware'
import { onAppear } from '@/utils/motion'

import type { PropType } from 'vue'
import type { Link, Picture } from '@/types'

export interface HeroPicture {
  headline?: string
  title: string
  text: string
  mobile_picture: Picture
  desktop_picture: Picture
  links: Link[]
}

defineProps({
  content: {
    type: Object as PropType<HeroPicture>,
    required: true,
  },
})

const rootEl = ref<HTMLElement | null>(null)
const isMobile = ref(false)
const pictureEl = ref<HTMLElement | null>(null)
const headlineEl = ref<HTMLElement | null>(null)
const titleEl = ref<HTMLElement | null>(null)
const textEl = ref<HTMLElement | null>(null)
const linksEl = ref<HTMLElement | null>(null)

const onWindowResize = () => {
  isMobile.value = window.innerWidth < 1024
}

onMounted(() => {
  useResize(onWindowResize)

  if (!rootEl.value) {
    return
  }

  gsap.set(rootEl.value, { opacity: 0 })

  // Animate on appear
  onAppear(
    rootEl.value,
    () => {
      appear()
    },
    {
      once: true,
      rootMargin: '0px 0px -20% 0px',
      waitForHero: false,
    }
  )
})

// Create appear animation
const appear = () => {
  if (!rootEl.value) {
    return
  }

  const animatedEls: Element[] = [rootEl.value]

  const animatedElsEl = [
    headlineEl.value,
    titleEl.value,
    textEl.value,
    linksEl.value,
  ]
    .map(el => el || null)
    .filter(Boolean)

  const tl = gsap.timeline({
    onComplete: () => {
      gsap.set(animatedEls, { clearProps: 'all' })
    },
  })

  tl.set(rootEl.value, { clearProps: 'opacity' })
    .set(animatedElsEl, {
      opacity: 0,
      y: 10,
    })
    .set(pictureEl.value, {
      webkitFilter: 'blur(40px)',
      scale: 1.2,
    })

  tl.add('start')
    .to(
      pictureEl.value,
      {
        webkitFilter: 'blur(0px)',
        scale: 1,
        opacity: 1,
        y: 0,
        ease: '8020',
        delay: 0.3,
        duration: 0.6,
      },
      'start'
    )
    .to(
      animatedElsEl,
      {
        opacity: 1,
        y: 0,
        ease: '8020',
        delay: 0.55,
        duration: 0.4,
        stagger: 0.15,
      },
      'start'
    )
}
</script>

<style lang="scss" scoped>
$bp: 'l';

.hero-picture {
  position: relative;
  display: flex;
  overflow: hidden;
  height: calc(100vh - var(--header-height));
  color: $c-white;

  @include mq($bp) {
    align-items: center;
    height: 70rem;
  }
}

.wrapper {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include mq($until: $bp) {
    margin-top: 6rem;
  }

  @include mq($bp) {
    padding-right: 46rem;
  }
}

.hero-links {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;

  @include mq($bp) {
    margin-top: 3rem;
  }
}

.hero-picture__container {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: $c-black;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.7;
  }
}
</style>
